@import '../variables';
.user-type-selector {
    .item {
        :hover {
            cursor: pointer;
        }
        p {
            font-size: 16px;
            color: $gray-128;
            font-weight: bold;
            padding-top: 3px;
        }
        .option {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid $gray-188;
        }
        &.selected {
            p {
                color: $dark-blue;
            }
            .option {
                background-color: $light-blue;
                border-color: $light-blue;
            }
        }
    }
}
