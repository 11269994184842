.password-change {
    .form-control {
        padding: 0.46rem 0rem;
        height: 38px;
        background-color: transparent;
    }
    .button-lg {
        width: 100%;
        min-height: 35px;
    }
    .rejected {
        color: $red-13;
        position: absolute;
        margin: 8px 4px;
    }
    .msg-error-parent {
        display: flex;
        margin: 38px 20px 30px 20px;
        align-items: center;
    }
}
