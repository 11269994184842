@import '../variables';
.multiple-request-modal {
    overflow-y: auto;
    .multiple-modal-content {
        padding: 50px 50px 60px 50px !important;
        display: flex !important;
        width: 900px;
        font-size: 14px;
        .swal2-title {
            color: $light-blue !important;
            font-size: 24px !important;
            text-align: left;
        }
        .checkbox-container {
            flex-wrap: wrap;
            .checkbox-item {
                min-width: 250px;
                margin: 3px 0;
                label {
                    cursor: pointer;
                    input {
                        cursor: pointer;
                        height: 15px;
                    }
                }
            }
        }
        .h-separator-2 {
            border-top: 2px solid $gray-188;
        }
        .swal2-input {
            font-size: 14px !important;
            border: none !important;
            color: $gray-128;
            height: auto !important;
            margin: 0em !important;
            padding: 10px 0 50px 0px !important;
        }
        .button-sm {
            width: 160px;
            height: 25px;
            padding: 5px 15px;
        }
    }
    .modal-hide {
        width: 100%;
        height: 1450px;
        position: absolute;
        z-index: -1;
    }
}

.return-transaction-modal {
    overflow-y: auto;
    .return-transaction-modal-content {
        padding: 50px 50px 60px 50px !important;
        display: flex !important;
        width: 500px;
        margin-top: 50px !important;
        font-size: 14px;
        .swal2-title {
            color: $light-blue !important;
            font-size: 24px !important;
            text-align: left;
        }
        .swal2-input {
            font-size: 14px !important;
            border: none !important;
            color: $gray-128;
            height: auto !important;
            margin: 0em !important;
            padding: 10px 0 50px 0px !important;
        }
        .button-sm {
            width: 160px;
            height: 25px;
            padding: 5px 15px;
        }
    }
    .modal-hide {
        width: 100%;
        height: 1450px;
        position: absolute;
        z-index: -1;
    }
}
