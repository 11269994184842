.button-container {
    display: grid;
    place-items: center;
    button {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    button:disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }
    .button-lg {
        width: 100%;
    }
}
