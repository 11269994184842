@import '../scss/variables';

.login-page {
    background: transparent
        linear-gradient(127deg, $dark-blue 46%, $light-blue 100%) 0% 0%
        no-repeat padding-box;
    border: 1px solid $gray-112;
    opacity: 1;
    background-size: cover;
    //background-position: center;
    @media screen and (max-width: $tablet) {
        background-position: -550px center;
    }

    .light-blue {
        color: $light-blue;
    }
    .white {
        color: white;
    }
    .space-between {
        justify-content: space-between;
    }
    .font-10 {
        font-size: 10px;
    }
    .container {
        @media screen and (max-width: $tablet) {
            width: 85%;
            margin-top: 60px;
            margin-bottom: 60px;
        }
        .logo {
            width: 300px;
            margin-bottom: 60px;
            @media screen and (max-width: $mobile) {
                width: 200px;
            }
        }
        .login-container {
            border-radius: 12px !important;
            background: white;
            max-width: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 60px 50px;
            .login-form {
                width: 100%;
                h1 {
                    color: $dark-blue;
                    font-size: 35px;
                    font-weight: 400;
                    font-weight: bold;
                }
                .login-subtitle {
                    color: $dark-blue;
                    position: absolute;
                }
                form {
                    margin-top: 40px;
                    display: flex;
                    flex-direction: column;
                    min-height: 231px;

                    .positioning-login {
                        display: flex;
                        flex: 1;
                    }
                    .login-input {
                        margin-bottom: 30px;
                        .msg-error {
                            position: relative;
                            display: inherit;
                            span {
                                position: absolute;
                            }
                        }
                        input {
                            border: 0px;
                            border-bottom: 2px solid $gray-222;
                            border-radius: 0;
                            padding: 0px;
                            width: 100%;
                            outline: none;
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                    .login-buttons {
                        font-size: 16px;
                        font-weight: 400;
                        > div {
                            padding: 0px;
                        }
                        .btn-primary {
                            width: 100%;
                            margin: 20px 0px 15px 0px;
                            min-height: 44px;
                            border: none;
                        }
                    }
                }
            }
        }
        .info-container {
            background: transparent;
            border: none;
            color: white;
            @media screen and (max-width: $tablet) {
                margin-top: 40px;
            }
            .info-content {
                .text {
                    margin-top: 30px;
                    font-size: 24px;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 25.35px;
                    letter-spacing: -1.04px;
                    @media screen and (max-width: $mobile) {
                        font-size: 19px;
                    }
                    p {
                        margin-bottom: 5px;
                    }
                }
                h2 {
                    font-size: 32px;
                    font-weight: 900;
                    @media screen and (max-width: $mobile) {
                        font-size: 24px;
                    }
                }
                .info-icon-section {
                    display: flex;
                    margin-top: 60px;
                    @media screen and (max-width: $mobile) {
                        flex-wrap: wrap;
                    }
                    .info-icon-item {
                        padding: 0px 20px;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        font-weight: bold;
                        &:nth-child(2) {
                            border-right: 1px solid white;
                            border-left: 1px solid white;
                            margin: 0px 0px;
                            padding: 0px 40px;
                            @media (max-width: $tablet) {
                                padding-left: 20px;
                                padding-right: 0px;
                            }
                            @media (max-width: $mobile) {
                                padding: 20px;
                                border-right: none;
                                border-left: none;
                            }
                        }
                        img {
                            width: 60px;
                            height: 50px;
                            margin-bottom: 15px;
                        }
                        @media (max-width: $tablet) {
                            width: 33.33%;
                            text-align: left;
                            padding-right: 0px;
                            &:first-child {
                                padding-left: 0px;
                            }
                        }
                        @media screen and (max-width: $mobile) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .grey-color {
        background-color: $gray-188;
    }
}
