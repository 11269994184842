.info-card {
    border-radius: 5px;
    background-color: $gray-235;
    padding: 20px 30px;

    .item {
        .value {
            font-size: 18px;
        }
    }
    .item:not(:last-child) {
        border-right: 2px solid $gray-188;
        padding-right: 30px;
    }
    .item:not(:first-child) {
        padding-left: 30px;
    }
}
