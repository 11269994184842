@import '../variables';
.errorBoundary {
    background: white;
    height: 100vh;

    .container {
        padding-top: 35vh;

        img {
            width: 100px;
        }

        p {
            color: $gray-128;
            font-size: 14px;
        }
    }
}
