@import '../variables';
.data-table-expandable {
    .tr {
        .th {
            font-size: 14px;
            color: $dark-blue;
            font-weight: bold;
        }
        .td {
            color: grey;
            border-top: 1px solid $gray-208;
        }
        .td,
        .th {
            padding: 10px 0 10px 0;
            > div {
                margin-right: 10px;
            }
            .width-25 {
                width: 25%;
            }
            .width-2 {
                width: 2%;
            }
            .width-5 {
                width: 5%;
            }
            .width-6 {
                width: 6%;
            }
            .width-7 {
                width: 7%;
            }
            .width-8 {
                width: 8%;
            }
            .width-9 {
                width: 9%;
            }
            .width-10 {
                width: 10%;
            }
            .width-11 {
                width: 11%;
            }
            .width-12 {
                width: 12%;
            }
            .width-13 {
                width: 13%;
            }
            .width-14 {
                width: 14%;
            }
            .width-15 {
                width: 15%;
            }
            .width-17 {
                width: 17%;
            }
            .width-19 {
                width: 19%;
            }
            .width-20 {
                width: 20%;
            }
            .width-30 {
                width: 30%;
            }
            .width-35 {
                max-width: 35%;
            }
            .width-40 {
                width: 30%;
            }
        }
    }
}
