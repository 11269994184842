.entry-saver-component {
    .input-item {
        input {
            border-radius: 18px;
            background-color: $gray-235;
            border: none;
        }
        label {
            margin-bottom: 1rem;
        }
    }
}
