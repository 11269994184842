@import '../scss/variables';
.header-fixed {
    .app-body {
        @media (min-width: $tablet-lg) {
            margin-top: 65px !important;
        }
        @media (max-width: $tablet-lg) {
            margin-top: 65px !important;
        }
    }
    .app-header {
        width: 99.3%;
        border-bottom: none;
        border-radius: 0 0 10px 0;
        box-shadow: 0 2px 20px rgba(black, 0.15);
        img.show-sidebar-toggler {
            cursor: pointer;
            height: 16px;
            box-sizing: content-box;
            padding: 13px 20px 20px;
        }
        .navbar-nav {
            .notification-badge {
                display: flex;
                a {
                    margin-bottom: -2px;
                    z-index: 999;
                    .icon-bell {
                        margin: -16px;
                        padding: 20px;
                    }
                }
                .badge-pill {
                    padding-right: 0.6em;
                    padding-left: 0.6em;
                    padding-top: 6px;
                    display: flex;
                    align-items: center;
                    background-color: $red-13;
                }
                .notification-menu {
                    top: 20px !important;
                    .dropdown-item {
                        .badge-danger {
                            background-color: $red-13;
                        }
                    }
                }
            }
            .dropdown-menu {
                top: auto !important;
                border: none;
                box-shadow: 0 10px 15px rgba(black, 0.15);
                border-radius: 10px;
                min-width: 280px;
                padding: 20px 30px;
                max-height: 80vh;
                overflow-y: scroll;
                @media (max-width: $mobile) {
                    top: 25px !important;
                }
                .dropdown-item {
                    display: flex;
                    padding: 10px 0;
                    color: $gray-128;
                    font-weight: bold;
                    outline: none;
                    &.account-select-list {
                        padding: 5px 20px;
                    }
                    .img-avatar {
                        margin: 0 10px 0 0;
                        max-width: 20px;
                        border-radius: 0em;
                    }
                    &:hover {
                        // padding-left: 7px;
                        // transition: padding-left .2s;
                        background: none;
                        color: $gray-95;
                        .nav-link {
                            color: $gray-95;
                        }
                    }
                    .search-field {
                        display: flex;
                        .search-input {
                            padding-left: 10px;
                            input {
                                border: none;
                                border-bottom: 1px solid $gray-208;
                                outline: none;
                            }
                        }
                        i {
                            margin: 0;
                        }
                    }
                }
                .account-select-title {
                    border-bottom: none;
                    padding: 10px 0;
                    &:hover {
                        color: $gray-128;
                        cursor: default;
                    }
                    p {
                        display: flex;
                        align-items: center;
                        height: 25px;
                    }
                }
                .account-select-list {
                    padding-left: 20px;
                    border-bottom: 0px;
                    .account-selected {
                        color: $dark-blue;
                    }
                    &:hover {
                        .nav-link {
                            color: $dark-blue;
                        }
                    }
                    &:last-child {
                        border-bottom: 1px solid blue;
                    }
                }
            }
            .nav-item {
                cursor: pointer;
                &:nth-child(2) {
                    border-right: 1px solid $gray-208;
                    border-left: 1px solid $gray-208;
                }
                i {
                    margin: 0 10px;
                }
                .account-info {
                    display: flex;
                    @media (max-width: $mobile-lg) {
                        .img-avatar {
                            display: none;
                        }
                        .account-name {
                            display: none;
                        }
                    }
                    .img-avatar {
                        max-width: 20px;
                        max-height: 20px;
                        border-radius: 0px;
                        height: auto;
                        margin-right: 10px;
                    }
                }
                .username {
                    color: $dark-blue !important;
                }
                .nav-link {
                    color: $gray-128;
                    font-weight: bold;
                    &:hover {
                        color: $gray-95;
                    }
                }
            }
        }
    }
}

// .sidebar-fixed {
//     @media (min-width: $tablet-lg) {
//         .app-header + .app-body .sidebar {
//             // height: calc(100vh - 65px);
//         }
//     }
// }
.show-sidebar-false {
    .sidebar {
        margin-left: -300px !important;
    }
}

.show-sidebar-true {
    .sidebar {
        margin-left: 0px !important;
        @media (max-width: $mobile-lg) {
            box-shadow: 0px 0px 0px 9999px rgba(black, 0.6);
        }
    }
}

.app-body {
    .sidebar {
        margin-top: -10px;
        width: 300px;
        background: $gray-128;
        .nav-dropdown-toggle::before {
            right: 3rem;
            transform: rotate(-90deg);
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
        }
        .nav-dropdown.open > .nav-dropdown-toggle::before {
            transform: rotate(90deg);
        }
        .sidebar-nav {
            width: 300px;
            .nav {
                width: 300px;
                padding-top: 10px;
                padding-bottom: 300px;
                .nav-dropdown-toggle {
                    background: $gray-128;
                }
                .nav-dropdown,
                .open {
                    .nav-dropdown-items {
                        box-shadow: inset 0px 5px 5px 0px rgba(black, 0.1);
                        .nav-item {
                            border-top: 1px solid $gray-128;
                            &:first-child {
                                border-top: 0px;
                            }
                            .nav-link {
                                padding-left: 1.7rem;
                            }
                        }
                    }
                }
                .open {
                    background: $gray-128 !important;
                    font-weight: normal !important;
                    .nav-dropdown-items {
                        background: $gray-128;
                        font-weight: normal;
                    }
                }
                .nav-item {
                    .nav-link {
                        &:hover {
                            background: $gray-95 !important;
                            font-weight: bold;
                        }
                    }
                    .nav-icon {
                        display: none;
                    }
                    .active {
                        background: $gray-95;
                        font-weight: bold;
                    }
                    .badge-info {
                        color: white;
                        background-color: $light-blue;
                        font-weight: 900;
                        font-size: 12px;
                    }
                    .badge {
                        border-radius: 50%;
                        padding: 7px 8px;
                    }
                }
            }
        }
    }
    .container-fluid {
        padding: 0 10px 10px 10px;
        .breadcrumb {
            background-color: transparent;
            border-bottom: none;
        }
        .iframe-page {
            padding: 0px !important;
        }
        .dashboard-view {
            border-radius: 10px;
            min-height: 666px !important;
            background: white;
            padding: 50px 50px 100px 50px;
            iframe {
                border: none;
                border-radius: 10px;
                min-height: 495px !important;
            }
        }
        .page-view {
            .page-title {
                margin-bottom: 40px;
            }
        }
    }
}
.aside-menu {
    display: none;
}

.app-footer {
    @media (min-width: $tablet-lg) {
        margin: 0 10px 0 10px !important;
    }
    border-radius: 10px 10px 0 0;
    background: $gray-188;
    font-size: 11px;
    font-weight: 400;
    color: white;
    padding: 25px;
    display: grid;
    grid-template-columns: 189px 730px auto;
    align-items: center;
    .footer-logo {
        text-align: center;
        img {
            width: 100px;
            margin-right: 32px;
        }
    }
    .footer-content {
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        @media (min-width: $tablet) {
            border-left: 1px solid white;
        }
        :first-child {
            font-size: 14px;
            font-weight: bold;
        }
    }
}
