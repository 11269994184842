@import '../../scss/variables';
.alert-small-question {
    padding: 75px 37px 40px 47px !important;
    min-height: 200px !important;
    .button {
        width: 75px;
        height: 25px;
        outline: 0;
        border-radius: 13px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 15px;
        border: none;
    }
    .swal-image {
        position: absolute;
        left: 47px;
        top: 37px;
        margin: 0 !important;
    }
    .swal2-title {
        color: $dark-blue !important;
        font-size: 14px !important;
        font-weight: bold !important;
        text-align: left !important;
    }
    .swal2-actions {
        justify-content: space-between !important;
        margin: 5px 0 0 0 !important;
    }
    .swal2-content {
        flex-grow: 1;
    }
}

.modal-md {
    padding: 50px 50px 55px 50px !important;
    min-height: 200px !important;

    .button {
        width: 160px;
        height: 25px;
        outline: 0;
        border-radius: 13px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 15px;
        border: none;
    }
    .swal-image {
        position: absolute;
        left: 47px;
        top: 37px;
        margin: 0 !important;
    }
    &.modal-approve {
        .swal2-title {
            color: $light-blue !important;
        }
    }
    &.modal-reject {
        .swal2-title {
            color: $red-13 !important;
        }
    }
    &.modal-request {
        .swal2-title {
            color: $light-blue !important;
        }
    }
    &.modal-submit {
        .swal2-title {
            color: $light-blue !important;
        }
        .swal2-content {
            // #swal2-content {
            //     font-weight: normal !important;
            // }
        }
        .swal2-actions {
            justify-content: flex-end !important;
            margin: 50px 0 0 0 !important;
            .swal2-cancel {
                margin-right: 20px;
            }
        }
        pre[class$="code-fold-content"] {
            &::before {
                visibility: visible;
                content: '....';
            }
            visibility: hidden;
        }
        [class$="code-fold"] a {
            text-decoration: none !important;
            cursor: default !important;
        }
    }
    .swal2-title {
        margin: 0 0 1.5em !important;
        font-size: 24px !important;
        font-weight: bold !important;
        text-align: left !important;
        width: 100%;
    }
    .swal2-actions {
        justify-content: space-between !important;
        margin: 5px 0 0 0 !important;
    }
    .swal2-content {
        flex-grow: 1;
        #swal2-content {
            color: $dark-blue !important;
            font-size: 14px !important;
            font-weight: bold !important;
            text-align: left !important;
            width: 100%;
            div {
                font-weight: normal !important;
            }
        }
    }
    .swal2-input {
        font-size: 14px !important;
        border: none !important;
        color: $gray-128;
        height: auto !important;
        margin: 0em !important;
        padding: 10px 0 20px 0px !important;
    }
    .swal2-validation-message {
        font-size: 14px !important;
    }
}

.modal-multiple {
    padding: 50px 50px 60px 50px !important;
    min-height: 200px !important;
    max-width: 1000px !important;
    .button {
        width: 160px;
        height: 25px;
        outline: 0;
        border-radius: 13px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 15px;
        border: none;
    }
    &.modal-Approving {
        .swal2-title {
            color: $light-blue !important;
            font-size: 24px !important;
        }
    }
    &.modal-Rejecting {
        .swal2-title {
            color: $red-13 !important;
            font-size: 24px !important;
        }
    }
    &.modal-Requesting {
        .swal2-title {
            color: $light-blue !important;
            font-size: 24px !important;
        }
    }
    &.modal-horizontalTable {
        table {
            th,
            td {
                border-right: none;
            }
        }
    }
    .swal2-title {
        margin: 0 0 1.5em !important;
        font-size: 24px !important;
        font-weight: bold !important;
        text-align: left !important;
        width: 100%;
    }
    .swal2-actions {
        justify-content: flex-end !important;
        margin: 5px 0 0 0 !important;
        .swal2-cancel {
            margin-right: 30px;
        }
    }
    .swal2-content {
        flex-grow: 1;
        #swal2-content {
            color: $gray-128 !important;
            font-size: 14px !important;
            text-align: left !important;
            width: 100%;
            .modal-table {
                width: 100%;
                tr {
                    border-bottom: 2px solid $gray-208;
                    &:last-child {
                        border-bottom: none;
                    }
                    th,
                    td {
                        padding: 10px 16px 10px 0;
                    }
                }
            }
            .checkbox-container {
                flex-wrap: wrap;
                .checkbox-item {
                    min-width: 250px;
                    margin: 3px 0;
                    label {
                        cursor: pointer;
                        input {
                            cursor: pointer;
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
    .swal2-input {
        font-size: 14px !important;
        border: none !important;
        color: $gray-128;
        height: auto !important;
        margin: 0em !important;
        padding: 10px 0 50px 0px !important;
    }
    .swal2-validation-message {
        font-size: 14px !important;
    }
    .checkbox {
        label {
            cursor: default !important;
            margin-bottom: 0px !important;
            .fa-check-square {
                path {
                    fill: $dark-blue !important;
                }
            }
        }
    }
    .dark-blue {
        p {
            color: $dark-blue !important;
        }
    }
}

.small-popup {
    box-shadow: 2px 2px 16px;
    width: 200px;
    height: 115px;
    .swal2-header {
        padding: 14px 0px 0px 0px;
        .swal2-title {
            color: $light-blue;
        }
    }
}

.small-error-popup {
    box-shadow: 2px 2px 16px;
    height: 200px;
    .swal2-header {
        padding: 0px 0px 10px 0px;
        align-items: start !important;
        .swal2-title {
            color: $red-13;
            font-size: 24px;
        }
    }
    .swal2-content {
        font-size: 14px !important;
        color: $gray-128 !important;
        div {
            text-align: left !important;
        }
    }
    .swal2-actions {
        .swal2-cancel {
            padding: 4px 2px 2px 2px !important;
            min-width: 201px !important;
            min-height: 35px !important;
            font-size: 14px !important;
            border-radius: 20px !important;
            font-weight: bold !important;
        }
    }
}
