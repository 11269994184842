@import '../variables';

.ui-button {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(black, 0.4);
    background-color: $blue-1;
    padding: 3px 16px;
    min-width: 84px;
    outline: none !important;
    border: solid 0.6px $blue-1;
    &--secondary {
        border: solid 0.6px $gray-128;
        background-color: $gray-128;
    }
    &--alt {
        border: solid 0.6px $blue-1;
        box-shadow: 0 1px 3px 0 rgba($blue-1, 0.4);
        background-color: white;
    }
    &--disabled {
        opacity: 0.5;
        box-shadow: 0 1px 3px 0 rgba($blue-1, 0.3);
        border: solid 0.6px $gray-128;
        background-color: white;
    }
    &--big {
        padding: 3px 52px !important;
    }
    &--inline {
        display: inline-block;
    }
    &--withPadding {
        margin-right: 12px;
    }
}

.button-group {
    .ui-button {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
}
