/**
white = white is used as primary background in the application
black: black;
red: is used for coloring error message

*/
$font: 'Basier-Square';

$tablet-lg: 992px;
$tablet: 768px;
$mobile-lg: 576px;
$mobile: 480px;

/* --------------------- */
/** dark colors from 0 - 49 */
/* --------------------- */
$black-38: rgb(38, 38, 38);

/* --------------------- */
/** gray colors from 50 - 100 */
/* --------------------- */
$gray-50: rgb(50, 50, 50);
$gray-72: rgb(72, 72, 72);
$gray-95: rgb(95, 95, 95);

/* --------------------- */
/** gray colors from 100 - 150 */
/* --------------------- */
$gray-112: rgb(112, 112, 112);
$gray-128: rgb(128, 128, 128); // used as secondaryBorder
/* --------------------- */
/** gray colors from 150 - 200 */
/* --------------------- */
/** gray-156 is used as
1. secondaryText
2. disabledBorder
3. input border*/
$gray-156: rgb(156, 156, 156);
$gray-172: rgb(172, 172, 172);
/** $gray-188 is used as light gray for borders*/
$gray-188: rgb(188, 188, 188);

/* --------------------- */
/** gray colors from 200 - 256 */
/* --------------------- */

$gray-208: rgb(208, 208, 208);

/* $gray-222 is used as
1. secondary table border
*/
$gray-222: rgb(222, 222, 222);

/** gray-235 is used for
1. border-separator
2. background-color of some input
3. light border*/
$gray-235: rgb(235, 235, 235);

/* gray-251 is used as white shade of table background */
$gray-251: rgb(251, 251, 251);

/*Light colors------*/
$beige-shadow: hsl(13, 96%, 91%);
/* --------------------- */
/* blue colors */
/* --------------------- */
$light-blue: hsl(195, 100%, 44%);
$blue-195: hsl(195, 63%, 65%);
$blue-195-2: hsl(195, 50%, 74%);
$blue-195-3: hsl(195, 75%, 41%);
$altBackground: hsl(195, 100%, 95%);

$tabBorder: hsl(200, 6%, 90%);
$blue-border-1: hsl(203, 76%, 82%);
$blue-1: hsl(207, 100%, 40%);
$hovered-blue-1: lighten(
    $color: $blue-1,
    $amount: 15%,
);
$primaryVlBorder: hsl(207, 11%, 81%);
$dark-blue: hsl(232, 47%, 24%);

/* --------------------- */
/* green colors */
/* --------------------- */
$infoSuccess: hsl(89, 59%, 48%);
$success: hsl(89, 84%, 39%);
$light-green: hsl(128, 52%, 47%);
$bright-cyan: hsl(153, 100%, 53%);
$info-cyan: hsl(153, 100%, 87%);
/* --------------------- */
/* red colors */
/* --------------------- */
$warning: hsl(38, 100%, 64%);
$red-346: hsl(346, 77%, 44%);
$red-13: hsl(13, 96%, 53%);

$radio-label-left-padding: 80px;


// New Colors for Batch GL Entry Page
$gray-192: rgb(192, 192, 192);
$blue: hsl(190, 71%, 40%);
$blue-190-2: lighten($blue, 20%);
$black-33: rgb(33, 37, 41);