// Custom styles
p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

iframe {
    body {
        background: white;
        .page-container {
            .card {
                box-shadow: none;
                background: white;
            }
        }
    }
}

.data-table-expandable {
    margin-top: 80px;
    color: $dark-blue;

    .tr {
        .icon-select {
            margin-bottom: 0rem;
            z-index: 9;
        }

        .no-margin {
            margin-right: 0px;
        }

        .expanded {
            border-top: 4px solid $gray-235;
        }

        .faded {
            transition: 0.3s;
            opacity: 0.5;
        }

        .row-item {
            cursor: pointer;

            .transactionId {
                height: 18px;
                z-index: 9;
                cursor: text;
            }

            .toggle_row {
                height: 65px;
                width: 80%;
                position: absolute;
            }
        }

        .td {
            color: $dark-blue  !important;

            .row-action-buttons {
                margin-right: 0px;
                text-align: right;

                .space-30px {
                    height: 30px;
                }
            }
        }
    }

    .expanded-info {
        padding: 30px 0 0 0px;

        table {
            tbody {
                .table-h-title {
                    height: 60px;
                    vertical-align: top;
                }

                tr {
                    td {
                        &:first-child {
                            color: $dark-blue;
                            font-weight: bold;
                            width: 200px;
                        }
                    }
                }
            }
        }

        .action-buttons {
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    .aml-container {
        border-top: 1px solid $gray-208;
        border-bottom: 1px solid $gray-235;

        .aml-item {
            padding: 30px 0 30px 0;

            .button-text {
                min-width: 36px;
                display: inline-block;
            }
        }

        .aml-item-expanded {
            padding-bottom: 30px;

            .col-name {
                color: $dark-blue;
            }
        }

        .min-space {
            width: 20%;
        }

        .max-space {
            max-width: 80%;
        }
    }
}

.data-table-tooltip {
    .tooltiptext {
        visibility: hidden;
        margin-top: -50px;
        margin-left: -27px;
        position: absolute;
        background: white; // border: 1px solid $gray-208;
        padding: 20px;
        max-width: 250px;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        word-wrap: break-word;
        box-shadow: 0 10px 30px rgba(black, 0.2);
        border-radius: 10px;
        background-color: white;
        cursor: pointer;
        opacity: 0;
        transition: visibility 0.1s linear, opacity 0.1s linear;
    }
}

.data-table-tooltipv2 {
    .tooltiptextv2 {
        visibility: hidden;
        margin-top: -50px;
        margin-left: -27px;
        position: absolute;
        background-color: white !important;
        padding: 20px;
        max-width: 500px;
        min-width: 300px;
        color: black !important;
        font-weight: 500;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        word-wrap: break-word;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        cursor: pointer;
        opacity: 1 !important;
        transition: visibility 0.1s linear, opacity 0.1s linear;
        border: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 1000;
    }
}
.data-table-tooltip.show_tooltip {
    &:hover {
        .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
}

.data-table-tooltipv2.show_tooltip {
    &:hover {
        .tooltiptextv2 {
            visibility: visible;
            opacity: 1;
        }
    }
}
.high-zIndex {
    z-index: 999;
}