.link-button {
    &:active {
        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0px !important;
        color: $light-blue !important;
    }
    background-color: transparent;
    border-color: transparent;
    padding: 0px;
    color: $light-blue;
    width: 40px !important;
}
