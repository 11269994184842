@import '../variables';
.data-table-v2 {
    color: $dark-blue;

    tr:first-of-type th:first-of-type,
    tr td:first-of-type {
        border-left: none;
        text-align: left;
        padding-left: 0;
    }

    tr {
        td,
        th {
            border-bottom: 2px solid $gray-208;
            padding: 10px;

            &:last-child {
                padding-right: 0;
            }
        }

        th {
            padding: 0px 10px 5px 10px;
            text-align: left;
            font-weight: bold;
        }

        td {
            vertical-align: top;

            a {
                font-weight: normal;
            }
        }

        &:last-child {
            td {
                border-bottom: none;
                padding-top: 10px;
                padding-bottom: 0px;
                height: 0px;
            }
        }
    }
}
