.input-item,
.filter-item {
    input,
    .basic-multi-select {
        color: $dark-blue !important;
        margin-top: 3px;
    }
}

.input-item-transparent {
    .form-control {
        height: 38px;
        &:focus {
            border-color: $gray-188;
        }
    }

    input {
        outline: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $gray-188;
        padding-left: 0;
    }

    label {
        margin-bottom: 1rem;
    }
}

.input-item-grey {
    input {
        border-radius: 18px;
        background-color: $gray-235 !important;
        border: none;
        width: 100%;
        margin-top: 3px;
    }

    label {
        margin-bottom: 1rem;
    }
}

.select-item-transparent {
    label {
        margin-bottom: 1rem;
    }

    .__select {
        &.status, &.data-test-clientFilter {
            z-index: 11;
        }

        &.currency {
            z-index: 10;
        }

        .select__control {
            border: none;
            box-shadow: none;
            border-bottom: 2px solid $gray-188;
            border-radius: 0px;

            .select__single-value {
                color: $dark-blue;
            }

            .select__indicators {
                .select__indicator-separator {
                    display: none;
                }

                .select__dropdown-indicator {
                    padding: 0px;
                }
            }

            .select__multi-value {
                border-radius: 0px;
            }

            .select__value-container {
                padding: 2px 0px;

                .select__single-value {
                    color: $dark-blue;
                }

                .select__multi-value__label {
                    color: $dark-blue;
                }
            }
        }

        .select__menu {
            color: $gray-128;
            margin: 0px;
            margin-top: -2px;
            border: 2px solid $gray-188;
            border-radius: 0px;
            outline: none;
            box-shadow: none;
            z-index: 3;

            .select__option:not(:last-child) {
                border-bottom: 1px solid $gray-188;
            }

            .select__option--is-focused {
                background: none;
                color: $dark-blue;
            }

            .select__option--is-selected {
                background: none;
                color: $dark-blue;
            }
        }
    }
}

.checkbox-item {
    .fa-square {
        border-radius: 1px;
        border: 1px solid $gray-188;
        width: 12px;
        height: 12px;
        margin-top: -3px;
    }
    .fa-square path {
        fill: white;
    }
    .fa-check-square {
        margin-top: -3px;
    }
    .fa-check-square path {
        fill: $light-blue;
    }
}

.input-item-grey2 {
    input {
        border-radius: 18px;
        background-color: $gray-235 !important;
        border: none;
        margin-top: 10px;
        padding: 7px 7ch;
    }
}
