.multi-select {
    .dropdown {
        .dropdown-heading {
            border: none !important;
            border-bottom: 2px solid $gray-188 !important;
            border-radius: 0px !important;
            height: 38px !important;
            .dropdown-heading-value {
                line-height: 36px !important;
                padding-left: 0px !important;
                padding-right: 5px !important;
                max-width: 90% !important;
                color: $dark-blue !important;
            }
            .dropdown-heading-loading-container {
                cursor: auto !important;
            }
            .dropdown-heading-dropdown-arrow {
                cursor: auto !important;
                font: normal normal normal 14px/1 FontAwesome, sans-serif;
                font-size: 1.5em !important;
                font-weight: bold;
                color: $gray-208;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                span {
                    display: none !important;
                }
                &:hover {
                    color: $gray-156;
                }
                &:focus {
                    color: $gray-208;
                }
            }
            .dropdown-heading-dropdown-arrow:before {
                content: '\f107';
            }
            .dropdown-heading-dropdown-cross {
                cursor: auto !important;
                font: normal normal normal 14px/1 FontAwesome, sans-serif;
                font-size: 1.5em !important;
                font-weight: bold;
                color: $gray-208;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                span {
                    display: none !important;
                }
                &:hover {
                    color: $gray-156;
                }
                &:focus {
                    color: $gray-208;
                }
            }
            .dropdown-heading-dropdown-cross:before {
                content: '\00d7';
            }
        }
        .dropdown-content {
            border: 2px solid $gray-188 !important;
            border-radius: 0px !important;
            max-height: 297px !important;
            margin-top: -2px !important;
            z-index: 100 !important;
            .select-panel {
                div {
                    border-bottom: 2px solid $gray-188 !important;
                    input {
                        border: none !important;
                    }
                    input::placeholder {
                        color: $gray-172;
                    }
                    input[type='text'] {
                        color: $dark-blue;
                    }
                }
                .select-item {
                    margin-bottom: 0px !important;
                    background-color: white !important;
                    border-bottom: 1px solid $gray-188 !important;
                    margin-right: 0px;
                    cursor: auto !important;
                    .item-renderer {
                        span {
                            padding: 2px 10px !important;
                        }
                    }
                    &:hover {
                        color: $dark-blue !important;
                    }
                }
                .select-list {
                    li:last-child {
                        .select-item {
                            border-bottom: none !important;
                        }
                    }
                }
            }
        }
    }
}
