@import '../variables';
.styled-select {
    * {
        outline: none;
    }

    font-size: 12px;

    &:focus,
    &.is-pseudo-focused,
    &.is-focused {
        outline: none;
    }

    .select__placeholder {
        padding-left: 0;
        font-weight: bold;
    }

    .select__indicator-separator {
        display: none;
    }

    .select__control {
        cursor: pointer;
        outline: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $gray-188;

        .select__value-container {
            color: $dark-blue;
            font-weight: bold;
            padding-left: 0;

            &:focus {
                outline: none;
            }

            .select__value-label {
                color: $dark-blue !important;
            }
        }

        .select__input {
            width: 100%;
            padding-left: 0;
        }
    }
    .select__control--is-focused {
        outline: none !important;
        // border: none !important;
        box-shadow: none !important;
        &:hover {
            border-color: $gray-188;
        }
    }

    .select__menu {
        border-radius: 0;
    }

    .select__menu-list {
        padding: 0;
    }

    .select__indicator {
        padding-right: 0;
        text-align: right;

        path: {
            color: $gray-188;
        }

        svg {
            color: $gray-188 !important;
        }
        &:hover {
            svg {
                color: $gray-188 !important;
            }
        }

        .select__arrow {
            border-width: 4px 4px 2px;
        }
    }

    .select__clear-zone {
        text-align: right;
        .Select-clear {
            font-size: 12px;
        }
    }

    .select__option {
        cursor: pointer;
        border-bottom: 1px solid $gray-188;
        border-radius: 0;

        &:hover,
        &.is-selected,
        &.is-focused,
        &:focus {
            background-color: white;
            color: $dark-blue;
            font-weight: bold;
        }
    }
    .select__option--is-selected,
    .select__option--is-focused {
        background-color: white;
        color: $dark-blue;
        font-weight: bold;
    }

    &.is-open {
        .select__arrow-zone {
            .select__arrow {
                border-width: 0px 4px 4px;
            }
        }
    }

    &.is-focused:not(.is-open) {
        .select__control {
            border-color: $gray-188;
            box-shadow: none;
        }
    }
}
