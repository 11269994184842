@import '../variables';
.pagination-container {
    .show-of {
        color: $gray-128;
    }
    .v-separator {
        height: 30px;
        border-left: 1px solid $gray-188;
    }
    .pagination-select {
        p {
            color: $gray-128;
        }
        .basic-select {
            .select__control {
                border: none;
                box-shadow: none;
                border-bottom: 2px solid $gray-188;
                border-radius: 0px;
                width: 66px;
                .select__single-value {
                    color: $dark-blue;
                }
                .select__indicators {
                    .select__indicator-separator {
                        display: none;
                    }
                    .select__dropdown-indicator {
                        padding: 6px;
                    }
                }
                .select__value-container {
                    padding: 0px;
                }
            }
            .select__menu {
                color: $gray-128;
                margin: 0px;
                margin-top: -2px;
                border: 2px solid $gray-188;
                border-radius: 0px;
                outline: none;
                box-shadow: none;
                width: 66px;
                .select__menu-list {
                    .select__option:not(:last-child) {
                        border-bottom: 1px solid $gray-188;
                    }
                    .select__option--is-focused {
                        background: none;
                        color: $dark-blue;
                    }
                    .select__option--is-selected {
                        background: none;
                        color: $dark-blue;
                    }
                }
            }
        }
    }
    .pagination {
        margin-top: 0px;
        flex: 1;
        margin-bottom: 0px;
        .next,
        .previous {
            a {
                background: none;
                &:hover {
                    background-color: $gray-235;
                }
            }
        }
        .selected {
            a {
                background-color: $gray-128 !important;
            }
        }
        .active {
            a {
                z-index: 1;
                color: white;
                background-color: $blue-195-3;
                border-color: $blue-195-3;
            }
        }
        li {
            padding-right: 7px;
            a {
                font-weight: 900;
                font-size: 10px;
                outline: 0;
                position: relative;
                display: block;
                padding: 0.3rem 0.55rem;
                margin-left: -1px;
                line-height: 1.25;
                color: white;
                background-color: $gray-188;
                border-radius: 50px;
                i {
                    color: $gray-128;
                }
                &:hover {
                    z-index: 2;
                    color: white;
                    text-decoration: none;
                    background-color: $gray-128;
                    cursor: pointer;
                }
            }
        }
    }
}
