@import '../scss/variables';

// Positioning
.float-r {
    float: right;
}

.float-l {
    float: left;
}

.vertical-center {
    align-items: center;
    display: flex;
}

.vertical-top {
    vertical-align: top;

    tr {
        td {
            vertical-align: top;
        }
    }
}

.vertical-bottom {
    align-self: flex-end;
}

.horizontal-center {
    justify-content: center;
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.horizontal-left {
    justify-content: flex-start;
    display: flex;
}

.horizontal-right {
    justify-content: flex-end;
    display: flex;
}

.text-right {
    text-align: right;
}

.full-width {
    width: 100%;
}

.flex {
    display: flex;
}

.flex-imp {
    display: flex !important;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.wrap {
    flex-wrap: wrap;
}

.flex-1 {
    flex: 1;
}

.absolute {
    position: absolute;
}

.mt-6 {
    margin-top: 6rem !important;
}

.mb-6 {
    margin-bottom: 6rem !important;
}

.mb-9 {
    margin-bottom: 9rem !important;
}

.margin-t-50px {
    margin-top: 50px;
}

.margin-t-30px {
    margin-top: 30px;
}

.margin-t-40px {
    margin-top: 40px;
}

.padding-t-15px {
    padding-top: 15px;
}

.padding-b-15px {
    padding-bottom: 15px;
}

.padding-r-15px {
    padding-right: 15px;
}

.padding-r-30px {
    padding-right: 30px;
}

.padding-l-15px {
    padding-left: 15px;
}

.padding-l-30px {
    padding-left: 30px;
}

.padding-r-60px {
    padding-right: 60px !important;
}

.padding-l-60px {
    padding-left: 60px !important;
}

.max-w70 {
    max-width: 70px;
}

.mr-11 {
    margin-right: 11rem;
}

.ml-10 {
    margin-left: 10px;
}

.max-w80 {
    max-width: 80px;
}

.max-w100 {
    max-width: 100px;
}

.max-w150 {
    max-width: 150px;
}

.max-w130 {
    max-width: 130px;
}

.max-w200 {
    max-width: 200px;
}

.max-w250 {
    max-width: 250px;
}

.max-w300 {
    max-width: 300px;
}

.max-w350 {
    max-width: 350px;
}

.max-w400 {
    max-width: 400px;
}

.max-w450 {
    max-width: 450px;
}

.max-w500 {
    max-width: 500px;
}

.max-w525 {
    max-width: 525px;
}

.max-w700 {
    max-width: 700px;
}

.min-w90 {
    min-width: 90px;
}

.min-w190 {
    min-width: 190px;
}

.min-w250 {
    min-width: 250px;
}

// Buttons
.btn-primary {
    outline: 0;
    border-radius: 21px;
    background-color: $light-blue;
    font-weight: bold;
}

.btn-primary-grey {
    outline: 0;
    border-radius: 20px;
    background-color: $gray-188;
    color: white;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 30px;
    border: none;
}

.btn-secondary-grey {
    outline: 0;
    border-radius: 20px;
    background-color: $gray-128;
    color: white;
    font-size: 12px;
    font-weight: 400;
    border: none;
    padding: 4px 20px;
}

.btn-link {
    outline: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
}

.button-xs {
    min-width: 85px;
    height: 25px;
    line-height: 27px;
    font-size: 12px;
    border-radius: 20px;
    font-weight: bold;
    color: white !important;
    outline: none !important;
    border: none;
    padding: 0rem;
    cursor: pointer;
}

.button-sm {
    padding: 4px 2px 2px 2px;
    min-width: 142px;
    min-height: 25px;
    font-size: 12px;
    border-radius: 20px;
    font-weight: bold;
    color: white !important;
    outline: none !important;
    border: none;
    cursor: pointer;
}

.button-md {
    padding: 4px 2px 2px 2px;
    min-width: 176px;
    min-height: 25px;
    font-size: 12px;
    border-radius: 20px;
    font-weight: bold;
    color: white !important;
    outline: none !important;
    border: none;
    cursor: pointer;
}

.button-lg {
    padding: 4px 2px 2px 2px;
    min-width: 201px;
    min-height: 35px;
    font-size: 14px;
    border-radius: 20px;
    font-weight: bold;
    color: white !important;
    outline: none !important;
    border: none;
    cursor: pointer;
}

.button-xlg {
    padding: 4px 2px 2px 2px;
    min-width: 301px;
    min-height: 38px;
    font-size: 14px;
    border-radius: 20px;
    font-weight: bold;
    color: white !important;
    outline: none !important;
    border: none;
    cursor: pointer;
}

.bg-light-grey {
    background-color: $gray-188;

    &:hover {
        background-color: $gray-172;
    }

    &:active {
        background-color: $gray-128;
    }
}

.button-light-blue {
    background-color: $light-blue !important;
}

.button-light-blue:focus {
    background-color: $light-blue !important;
}

.button-light-blue:hover {
    background-color: $light-blue !important;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;

    &:hover {
        background-color: $gray-188;
    }
}

.button-loading {
    font-size: 16px;
}

// ==
.color-dark-blue {
    color: $dark-blue !important;
}

.color-light-blue {
    color: $light-blue !important;
}

.color-blue {
    color: $blue-1;
}
.color-light-grey {
    color: $gray-128;
}

.msg-error {
    color: red !important;
}

.msg-success {
    color: green !important;
}

.hide {
    display: none;
}

.capitalize {
    text-transform: lowercase !important;

    &:first-letter {
        text-transform: uppercase;
    }
}

// Actions
.btn-hover-blue:hover {
    background-color: $light-blue;
}

.btn-hover-blue:active {
    background-color: $light-blue;
}

.btn-focus-blue:focus {
    background-color: $light-blue;
}

// Text styles
.page-title {
    color: $dark-blue;
    font-weight: bold;
    font-size: 30px;
}

.title-h2-bold {
    color: $dark-blue;
    font-weight: bold;
    font-size: 24px;
}

.title-h3-bold {
    color: $dark-blue;
    font-weight: bold;
    font-size: 18px;
}

.title-h3-medium {
    color: $dark-blue;
    font-weight: normal;
    font-size: 18px;
}

.font-size-16px {
    font-size: 16px;
}

.text-bold {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.form-group {
    vertical-align: top;
}

.dark-blue {
    color: $dark-blue !important;
}

.light-grey {
    color: $gray-188;
}

.light-blue {
    color: $light-blue;
}

a:hover {
    text-decoration: none;
    color: $dark-blue;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link {
    color: #1e96ae;
    cursor: pointer;
}

* {
    outline: none;
}

.h-separator {
    border-top: 1px solid $gray-188;
    width: 100%;
}

.comma + .comma:before {
    content: ',';
    margin-right: 2.5px;
}

.split {
    border-left: 1px solid;
    height: 35px;
}

textarea {
    resize: none;
}

.no-bullet {
    padding: 0;
    list-style-type: none;
}

.newline {
    white-space: pre;
}

.table-wrapper {
    min-width: 0;
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 1;
    align-items: stretch;
}

.arrow-down {
    margin-left: 5px;
    font: normal normal normal 14px/1 FontAwesome, sans-serif;
    font-weight: 900;

    &:before {
        content: '\f107';
    }
}

.arrow-up {
    margin-left: 5px;
    font: normal normal normal 14px/1 FontAwesome, sans-serif;
    font-weight: 900;

    &:before {
        content: '\f106';
    }
}

.mHeight300 {
    min-height: 300px !important;
}

.word-break-all {
    word-break: break-all;
}
.no-border {
    border: none !important;
}

.alert {
    box-sizing: border-box;
    background-color: $info-cyan;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 300;
    padding: 10px 20px;
    color: $gray-50;
}

@mixin box-shadow-1 {
    box-shadow: 0px 0px 8px 2px rgba(black, 0.3);
}
.no-records {
    display: grid;
    justify-content: center;
    padding: 25px;
    font-size: 16px;
}
.click-disabled {
    pointer-events: none;
    cursor: not-allowed;
}

.react-switch {
    margin-left: 10px !important;
    input[type="checkbox"] {
        display: none;
    }
}

.recieve-alerts {
    display: flex;
    flex-direction: row;
    #radioButtons {
        width: 20%;
    }
    #toggleButton {
        display: flex;
        justify-content: center;
        align-content: center;
        align-self: center;
        align-items: center;
    }
}