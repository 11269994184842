@import '../variables';
.data-table-regular {
    .text-bold {
        color: $dark-blue;
    }

    tr:first-of-type th:first-of-type,
    tr td:first-of-type {
        border-left: none;
        text-align: left;
        padding-left: 0;
    }

    tr {
        &.disabled {
            td {
                pointer-events: none;
                opacity: 0.6;
            }
        }

        &:last-child {
            td {
                border-bottom: none;
                padding-top: 10px;
                padding-bottom: 0px;
                height: 0px;
            }
        }
    }

    th {
        padding: 0px 10px 5px 10px;
        text-align: left;
        font-weight: bold;
        border-right: 1px solid $gray-208;
        border-bottom: 1px solid $gray-208;
        color: $dark-blue;

        &:last-child {
            border-right: none;
            padding-right: 0;
        }
    }

    td {
        line-height: 20px;
        color: $gray-128;
        text-align: left;
        padding: 10px;
        border-right: 1px solid $gray-208;
        border-bottom: 1px solid $gray-208;

        &:last-child {
            border-right: none;
            padding-right: 0;
        }

        a {
            color: $gray-128;
            font-size: 14px;
            font-weight: bold;
        }

        .delete {
            color: $gray-188;
            cursor: pointer;
            padding: 0 10px;

            &.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }

        &.disabled {
            pointer-events: none !important;
            opacity: 0.6;
        }
    }
    .no-border-r {
        border-right: none;
    }
}

.data-table-tooltip {
    .tooltiptext {
        visibility: hidden;
        margin-top: -50px;
        margin-left: -27px;
        position: absolute;
        background: white; // border: 1px solid $gray-208;
        padding: 20px;
        max-width: 250px;
        word-break: normal;
        word-spacing: normal;
        white-space: normal;
        line-break: auto;
        word-wrap: break-word;
        box-shadow: 0 10px 30px rgba(black, 0.2);
        border-radius: 10px;
        background-color: white;
        cursor: pointer;
        opacity: 0;
        transition: visibility 0.1s linear, opacity 0.1s linear;
    }
}

.data-table-tooltip.show_tooltip {
    &:hover {
        .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
    }
}

.gmo-data-table {
    th, td {
        padding: 10px 100px;
        border-left: 1px solid $gray-800;
        border-right: 1px solid $gray-800;
    }

    th {
        font-weight: bold;
        color: $dark-blue;
        border-top: 1px solid $gray-800;
        border-bottom: 1px solid $gray-800;
    }

    td {
        color: $gray-128;
        border-bottom: 1px solid $gray-400;
    }

    tr:last-child td {
        border-bottom: 1px solid $gray-800;  // Darker, thicker bottom border for last row
    }
}
