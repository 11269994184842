@import '../variables';
/* calendar styles */

.DateRangePickerInput {
    border-radius: 4px;
}

.DateRangePicker_picker {
    z-index: 10;
}

.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
    border-radius: 4px;
}

.DateInput.DateInput_1.DateInput__small.DateInput__small_2 {
    background: transparent;
}

.DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
    background: transparent;
    padding: 9px 7px 5px;
}

.DateInput_input__focused {
    border-bottom: 2px solid $dark-blue;
}

.CalendarMonth_caption {
    color: $dark-blue;
}

.CalendarDay__default {
    border: 1px solid white;
    color: white;
    background-color: $gray-222;
}

.CalendarDay__default:hover {
    background: $gray-188;
    border: 1px double white;
    color: white;
}

/* .CalendarDay__highlighted_calendar {
    color: $gray-72
  } */

/* .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    color: $gray-72
  } */

.CalendarDay__selected_span {
    background: $blue-195-2;
    border: 1px solid white;
    color: white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: $blue-195;
    border: 1px solid white;
    color: white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background-color: $light-blue;
    border: 1px solid white;
    color: white;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: $blue-195-2;
    border: 1px solid white;
    color: white;
}

.CalendarDay__hovered_span:active {
    background: $blue-195;
    border: 1px solid white;
    color: white;
}

.DayPickerKeyboardShortcuts_show {
    border-right: 33px solid $light-blue;
    display: none;
}

.DayPickerKeyboardShortcuts_show:hover {
    border-right: 33px solid $blue-195-3;
    display: none;
}
