@import '../variables';
form.filter-form {
    margin-top: 64px;

    .form-group {
        margin-right: 30px;
        display: inline-block;

        label {
            font-size: 18px;
            color: $dark-blue;
        }
        &.checkbox {
            label {
                cursor: pointer;
                font-size: 13px;
                color: $gray-128;
                input {
                    margin-right: 14px;
                }
            }
        }

        .DateRangePicker {
            .DateRangePickerInput {
                width: 100%;
                font-size: 14px;
                .DateInput__small {
                    width: 50%;
                    &:first-child {
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                    .DateInput_input__small {
                        padding: 7px 0 11px;
                        font-size: 14px;
                    }
                }
                .DateRangePickerInput_arrow {
                    display: none;
                }
            }
        }
    }

    input {
        outline: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $gray-188;
        padding-left: 0;
    }

    .form-control {
        outline: none;
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $gray-188;
        padding-left: 0;

        &:focus {
            box-shadow: none;
            border-bottom: 2px solid $gray-188;
        }
    }
}
